import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import DownloadApp from '../components/DownloadApp';
import ImproveYourLife from '../components/ImproveYourLife';
import { Link } from "gatsby"

export default () => (
  <Layout>
    <SEO title="Quit Smoking" />

    <div>
      <div className="section image-bg-section">
        <div className="container">
          <div className="content-width-medium">
            <h6 className="subheading">Quit Smoking</h6>
            <h1 className="no-bottom-space center-mobile">You are stronger than your addiction</h1><Link to="/pricing/" className="button margin-top w-inline-block">
              <div>Get started</div>
            </Link>
          </div>
        </div>
        <div style={{backgroundImage: 'url("/images/5ddc24335d412bd1d738c183_AdobeStock_99982712%20%281%29.jpeg")'}} className="image-background bg-image-1" />
        <div className="background-scrim-left" />
      </div>
      <div className="section">
        <div className="container">
          <div className="w-richtext">
            <p>Live a healthier, happier, addiction-free life. Tap your inner strength and deepen your resolve to gain control of your life.</p>
            <p>The WiseGuide App is entirely different from any other audio program for overcoming addiction. We will help you live one day at a time and enjoy every minute of each day as you prepare for an even better tomorrow. It promotes freedom from resentment and blame and greater acceptance of oneself and others. These are very effective programs and are a great addition to any support system.</p>
            <p>Start a FREE 7-day trial to experience how The WiseGuide App can help become stronger than your addiction. Just listen.</p>
          </div>
        </div>
      </div>
    </div>

    <ImproveYourLife />

    <DownloadApp />
  </Layout>
);
